<template>
  <div class="header-img">
      <input type="checkbox" id="input" hidden />
        <div class="box" id="jjjj">
          <div class="row justify-content-center">
            <label for="input" id="im">
              <div class="container input_taxistas">
                <div class="row justify-content-center">
                  <div class="col">
                    <form>
                      <button type="button" @click="returnHome()" class="btn-close" aria-label="Close">X</button>
                      <h1 class="text text-center mt-5">Regístro de Taxistas<br>
                        
                      </h1>
                      <div class="form-group mt-5">
                          <label>Por favor, introduce el código envíado a tu teléfono:</label>
                          <input class="form-control form-control-lg mt-3" type="text" v-model="codigo" required placeholder="Código sms" autocomplete="off">
                          </div>
                      <p v-if="isInvalid === true" class="alert alert-warning">Parece que existe un error en los datos introducidos, por favor, inténtalo de nuevo.</p>    
                      <!--MOCK PARA PODER ACCEDER DE FORMA TEMPORAL-->
                        <button type="submit" class="btn btn-dark btn-lg btn-block mt-5 mb-5" @click="mock()">Registrarse</button>
                        <!--
                      <button type="submit" class="btn btn-dark btn-lg btn-block mt-5 mb-5" @click="inputUser()">Registrarse</button>
                        -->     
                    </form>
                  </div>
                </div>
              </div>
            </label>
          </div>
            <div class="container-fluid input_taxistas_footer">
              <div class="row justify-content-center">
                <div class="col">
                  <figure>
                    <p class="col justify-content-left marca">
                      <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/cluf.html">Contrato de Licencia de Usuario Final</a>
                      <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/politicaprivacidad.html">Política de Privacidad</a>
                      <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/copyrightylicencia.html">Copyright y Licencia de software</a>
                      <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/licenciasdeterceros.html">Licencias de terceros</a>
                      <a class="external_link" target="_blank" href="http://www.2kbo.com/requerimientostecnicos.html">Requerimientos técnicos</a>
                    </p>
                    <p class="marca_kbo">© KBO 2021. Marca KBO. Versión 1.0</p>
                  </figure>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'inputTaxistasView',
  data() {
      return {
          codigo: '',
          isInvalid: false, //SE VUELVE TRUE SI EL CODIGO ES INVALIDOS 
      }
  },

  methods: {
    mock() {
        if(this.codigo === '1234') {
        this.$router.push('/login-taxistas')
        } 
        if(this.codigo !== '1234') {
          this.isInvalid = true
          this.codigo = ''//Asi vacia los campos una vez enviada la informacion
        }
      },
    returnHome() {
        this.$router.push('/')
    },
    //FUNCION DE GUARDAR LA URL EN LOCALSTORAGE
    setUrlBase(url) {
      localStorage.setItem('URL', url)
    },
    //FUNCION PARA GUARDAR EN LOCALSTORAGE EL TOKEN
    setAuthToken(token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem('AUTH_TOKEN_KEY', token)
    },
    //FUNCION ASINCRONA DE LOGIN, PUES HAY QUE ESPERAR UNA RESPUESTA DEL SERVIDOR
    async inputUser() {
      console.log(this.codigo);
      //SI CODIGO ESTA VACIO SALDRA VENTANA DE ERROR, CAMPOS REQUERIDOS
      if(this.codigo === '') {
        this.isInvalid = true
      } else {
        (this.codigo)
        axios.post('http://localhost:3000/codigo/', {
        codigo: this.codigo
        })
        .then((results) => {
          if(results === []) {
            this.isInvalid = true
          }
          else {
            if(results) {
            this.$emit('inputdone')
            this.$router.push('/login-taxistas')
            console.log(results);
            console.log(results.data[0].token);
            console.log(results.data[0].url);
                //ME GUARDO EL TOKEN
                this.setAuthToken(results.data[0].token)
                //ME GUARDO LA URL_BASE
                this.setUrlBase(results.data[0].url)
            }
          }
      })
      .catch((error) => {
        console.log('Parece que existe un error en el código introducido, por favor, inténtalo de nuevo');
        this.$router.push('/input-taxistas')
          this.isInvalid = true
      }) 
      this.codigo = ''//Asi vacia los campos una vez enviada la informacion
           
    }
  },
},
  
   
/**
    async input() { 
      axios.post('http://localhost:3000/codigo/', {
        codigo: this.codigo
      })
      .then((results) => {
        if(!results) {
          Swa1.fire({
            title: 'Por favor, introduzca un código válido',
            icon: 'error',
            confirmButtonText: 'Ok',
            background: 'black',
            confirmButtonColor: 'transparent',
                willClose: () => {
                this.isInvalid = true
                }
        }) 
        }
        else {
        if(results) {
        this.$emit('inputdone')
        this.$router.push('/login-taxistas')
        console.log(results);
        console.log(results.data[0].token);
        console.log(results.data[0].url);
        
            //ME GUARDO EL TOKEN
            this.setAuthToken(results.data[0].token)
            //ME GUARDO LA URL_BASE
            this.setUrlBase(results.data[0].url)
        }
      }
      })

      .catch((error) => {
        console.log('Parece que existe un error en el código introducido, por favor, inténtalo de nuevo');
        throw alert(error)
      }),
      this.codigo = ''//Asi vacia los campos una vez enviada la informacion
    }
  },
  */
}

</script>

<style lang="scss" src="/home/hab8/Documents/KBO/2kbo-publicwebsite/src/scss/estilos.scss" scoped></style>